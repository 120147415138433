///// fonts: @font-face delarations

///// styles
h1 {
  color: $brand-color;
  font-size: 3.5rem;
}

.ce_subheadline {
  margin-top: 0.25rem;
  font-weight: 700;
}

h2 {
  color: $brand-color;
  font-size: 2.25rem;
  line-height: 1.25;
}

h3 {
  font-size: 1.125rem;
  font-weight: 700;
}

h4 {
  font-size: 1.125rem;
  font-weight: 700;
  color: $brand-color;
  margin-bottom: 0.5rem;
}

// list
.ce_list li {
  font-size: 1.125rem;
  padding: 0.5rem 1rem 0.5rem 1.75rem;
  font-weight: 700;
  position: relative;
  &::before {
    content: url("../content/icons/physiotherapie-witzel-hook-icon.svg");
    position: absolute;
    left: 0;
    height: 1rem; width: 1rem;
    border-radius: 100%;
  }
}

.ce_text ul {
  margin-bottom: 1.5rem;
}

.ce_text li {
  font-size: 1.125rem;
  margin: 0.25rem 0 0.25rem 0.75rem;
  
  &::before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    height: 0.25rem; 
    width: 0.25rem;
    background: $text-color;
    border-radius: 100%;
    transform: translateX(-0.5rem);
    margin-left: -0.25rem;
  }
}

// accordion
.ce_accordion{
  border-bottom: 1px solid $border-color;
  border-left: 3px solid $brand-color;
  margin-bottom: 0.25rem;
  cursor: pointer;
  
  .accordion {
    padding: 1rem;
  }
  
  .toggler {
    padding: 1rem;
    padding-right: 2rem;
        
    // reset focus outline
    &:focus {
      outline: none;
    }
    
    // icon
    position: relative;
    
    &::after {
      position: absolute;
      content: "+";
      right: 0.5rem;
      top: 50%; transform: translateY(-50%);
      font-size: 2.25rem;
      font-weight: 300;
      opacity: 0.33;
      transition: 250ms ease-in-out;
    }
    
    &.ui-state-hover::after, &.ui-state-active::after {
      opacity: 1;
      color: $brand-color;
    }
    
    &.ui-state-active::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

// links
a, span {
  color: $brand-color;
  text-decoration: none;
  border-bottom: 1px solid $brand-color;
  font-size: 1.125rem;
  opacity: 1;
  transition: 250ms ease-in-out;
  
  // nav & footer
  [id=navigation] .menu &, [id=footer] .menu & {
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    border: none;
  }
  
  // nav + mobile
  .has-mobile-nav [id=navigation] & {
    font-size: 1.5rem;
  }
  
  // hover
  &:hover {
    opacity: 0.66;
  }
}

// active menu
strong {
  [id=footer] .menu & {
    color: $brand-color;
    font-weight: 600;
    font-size: 1.125rem;
    opacity: 0.66;
  }
}

// hyperlink
.ce_hyperlink a {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 0;
}

// button
.button a {
  border: 2px solid $brand-color;
  padding: 0.5rem 1rem;
  border-radius: 2.5rem;
  transition: 250ms ease-in-out;
  
  &:hover {
    background: $brand-color;
    color: white;
    opacity: 1;
  }
}

// paragraphe
p {
  font-size: 1.125rem;
  line-height: 1.75;
}

// bold, strong
b, strong {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

// copyline
.copyline {
  padding-top: 1.25rem;
  @include screen(large) {
    padding-top: 0;
  }
  text-align: center;

  span {
    font-size: 0.875rem;
    border-bottom: 0;
    
    &:hover {
      opacity: 1;
    }
  }
}

// scroll-down
.scroll-down span {
  color: $brand-color;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 0 0 0.5rem rgba(white, 0.5);
}