.mobile-menu-trigger {
  position: fixed;
  top: 1.5rem; right: 2rem;
  display: block;
  z-index: 9999;
  height: 3rem; width: 3rem;
  background: $brand-color;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.5;
  transition: 250ms ease-in-out;
  border: 0.125rem solid rgba(white, 0);
  
  @include screen(large) {
    display: none;
  }
  
  &, &:focus, &:hover, &:active {
    outline: 0; // reset
    box-shadow: none; // reset
  }
  
  span {
    @include visually-hidden;
  }
  
  &:hover {
    opacity: 1;
  }
  
  .show-mobile-nav & {
    opacity: 1;
    border: 0.125rem solid rgba(white, 1);
    background: rgba($brand-color, 0.95);
  }
}