  // keyframes
  @keyframes scrollDown {
    
    0% { 
      opacity: 0;
      bottom: -1rem;
    }
    
    25% {
      opacity: 0.33;
    }
    
    50% {
      opacity: 1;
      bottom: -2rem;
    }
    
    75% {
      opacity: 0.33;
    }
    
    100% {
      opacity: 0;
      bottom: -3rem;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
  }

.scroll-down {
  // responsiveness visibility
  display: none;
  @include screen(large) {
    opacity: 1; // opacity: 0;
    display: block;
    overflow: visible;
    animation-name: fadeIn;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-delay: 2500ms;
    animation-fill-mode: forwards;
  }
  
  // position
  position: absolute;
  left: 50%; transform: translateX(-50%);
  bottom: 9rem;
  cursor: pointer;
  
  // style
  background: none;
  border: none;
  
  &:focus {
    outline: 0;
  }
  
  span {
    border-bottom: none;
  }
  
  // arrow
  img {
    width: 1rem; height: 1rem;
    position: absolute;
    left: 50%; transform: translateX(-50%);
    bottom: 0;
    opacity: 0;
    transition: 250ms ease-in-out;
    animation-name: scrollDown;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-delay: 2500ms;
      cursor: default;
  }
}