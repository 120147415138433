[class^="mod_news"] {
  
  .info {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  h3>a {
    border-bottom: none;
  }
  
  h3 {
    margin-bottom: 0.5rem;
  }
  
  .ce_text > p {
    margin-bottom: 0.5rem !important;
  }
  
  .more > a  {
    font-size: 1rem;
    border-bottom: 0;
  }
}


.mod_newslist {
  width: 100%;
  
  @include screen(large) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    h2 {
      width: 100%;
      display: block;
    }
    
    .layout_latest {
      width: 47.5%;
    }
  }
  
  &::after {
    content: none !important;
  }
}

.mod_newsreader, .mod_newsarchive {
  &::after {
    content: none !important;
  }
  
  h2 {
    margin-bottom: 3rem;
  }
}