html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box;
  font-size: 90%; }
  @media (min-width: 541px) {
    html {
      font-size: 100%; } }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5B5B5B;
  font: normal 16px/1.5 "Raleway", sans-serif; }

.invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

img {
  width: 100%;
  height: auto; }

#wrapper {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column; }
  #wrapper #container {
    flex: 1 0 auto; }

.rs-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem; }
  .rs-columns > h1, .rs-columns > h2, .rs-columns > h3, .rs-columns > h4, .rs-columns > h5, .rs-columns > h6 {
    width: 100%; }

.rs-column {
  margin-bottom: 3rem; }
  .rs-column:last-child {
    margin-bottom: 0; }
  @media (min-width: 769px) {
    .rs-column {
      margin-bottom: 0; } }
  .rs-column.no-margin-bottom {
    margin-bottom: 0 !important; }

.rs-column {
  width: 100%; }
  @media (max-width: 320px) {
    .rs-column.-small-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-small-col-2-1 {
      width: calc(50% - 3.75%); }
    .rs-column.-small-col-2-2 {
      width: calc(100% - 3.75%); }
    .rs-column.-small-col-3-1 {
      width: calc(33.33333% - 5%); }
    .rs-column.-small-col-3-2 {
      width: calc(66.66667% - 5%); }
    .rs-column.-small-col-3-3 {
      width: calc(100% - 5%); }
    .rs-column.-small-col-4-1 {
      width: calc(25% - 5.625%); }
    .rs-column.-small-col-4-2 {
      width: calc(50% - 5.625%); }
    .rs-column.-small-col-4-3 {
      width: calc(75% - 5.625%); }
    .rs-column.-small-col-4-4 {
      width: calc(100% - 5.625%); }
    .rs-column.-small-col-5-1 {
      width: calc(20% - 6%); }
    .rs-column.-small-col-5-2 {
      width: calc(40% - 6%); }
    .rs-column.-small-col-5-3 {
      width: calc(60% - 6%); }
    .rs-column.-small-col-5-4 {
      width: calc(80% - 6%); }
    .rs-column.-small-col-5-5 {
      width: calc(100% - 6%); }
    .rs-column.-small-col-6-1 {
      width: calc(16.66667% - 6.25%); }
    .rs-column.-small-col-6-2 {
      width: calc(33.33333% - 6.25%); }
    .rs-column.-small-col-6-3 {
      width: calc(50% - 6.25%); }
    .rs-column.-small-col-6-4 {
      width: calc(66.66667% - 6.25%); }
    .rs-column.-small-col-6-5 {
      width: calc(83.33333% - 6.25%); }
    .rs-column.-small-col-6-6 {
      width: calc(100% - 6.25%); } }
  @media (min-width: 541px) {
    .rs-column.-medium-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-medium-col-2-1 {
      width: calc(50% - 3.75%); }
    .rs-column.-medium-col-2-2 {
      width: calc(100% - 3.75%); }
    .rs-column.-medium-col-3-1 {
      width: calc(33.33333% - 5%); }
    .rs-column.-medium-col-3-2 {
      width: calc(66.66667% - 5%); }
    .rs-column.-medium-col-3-3 {
      width: calc(100% - 5%); }
    .rs-column.-medium-col-4-1 {
      width: calc(25% - 5.625%); }
    .rs-column.-medium-col-4-2 {
      width: calc(50% - 5.625%); }
    .rs-column.-medium-col-4-3 {
      width: calc(75% - 5.625%); }
    .rs-column.-medium-col-4-4 {
      width: calc(100% - 5.625%); }
    .rs-column.-medium-col-5-1 {
      width: calc(20% - 6%); }
    .rs-column.-medium-col-5-2 {
      width: calc(40% - 6%); }
    .rs-column.-medium-col-5-3 {
      width: calc(60% - 6%); }
    .rs-column.-medium-col-5-4 {
      width: calc(80% - 6%); }
    .rs-column.-medium-col-5-5 {
      width: calc(100% - 6%); }
    .rs-column.-medium-col-6-1 {
      width: calc(16.66667% - 6.25%); }
    .rs-column.-medium-col-6-2 {
      width: calc(33.33333% - 6.25%); }
    .rs-column.-medium-col-6-3 {
      width: calc(50% - 6.25%); }
    .rs-column.-medium-col-6-4 {
      width: calc(66.66667% - 6.25%); }
    .rs-column.-medium-col-6-5 {
      width: calc(83.33333% - 6.25%); }
    .rs-column.-medium-col-6-6 {
      width: calc(100% - 6.25%); } }
  @media (min-width: 769px) {
    .rs-column.-large-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-large-col-2-1 {
      width: calc(50% - 3.75%); }
    .rs-column.-large-col-2-2 {
      width: calc(100% - 3.75%); }
    .rs-column.-large-col-3-1 {
      width: calc(33.33333% - 5%); }
    .rs-column.-large-col-3-2 {
      width: calc(66.66667% - 5%); }
    .rs-column.-large-col-3-3 {
      width: calc(100% - 5%); }
    .rs-column.-large-col-4-1 {
      width: calc(25% - 5.625%); }
    .rs-column.-large-col-4-2 {
      width: calc(50% - 5.625%); }
    .rs-column.-large-col-4-3 {
      width: calc(75% - 5.625%); }
    .rs-column.-large-col-4-4 {
      width: calc(100% - 5.625%); }
    .rs-column.-large-col-5-1 {
      width: calc(20% - 6%); }
    .rs-column.-large-col-5-2 {
      width: calc(40% - 6%); }
    .rs-column.-large-col-5-3 {
      width: calc(60% - 6%); }
    .rs-column.-large-col-5-4 {
      width: calc(80% - 6%); }
    .rs-column.-large-col-5-5 {
      width: calc(100% - 6%); }
    .rs-column.-large-col-6-1 {
      width: calc(16.66667% - 6.25%); }
    .rs-column.-large-col-6-2 {
      width: calc(33.33333% - 6.25%); }
    .rs-column.-large-col-6-3 {
      width: calc(50% - 6.25%); }
    .rs-column.-large-col-6-4 {
      width: calc(66.66667% - 6.25%); }
    .rs-column.-large-col-6-5 {
      width: calc(83.33333% - 6.25%); }
    .rs-column.-large-col-6-6 {
      width: calc(100% - 6.25%); } }

h1 {
  color: #EB672C;
  font-size: 3.5rem; }

.ce_subheadline {
  margin-top: 0.25rem;
  font-weight: 700; }

h2 {
  color: #EB672C;
  font-size: 2.25rem;
  line-height: 1.25; }

h3 {
  font-size: 1.125rem;
  font-weight: 700; }

h4 {
  font-size: 1.125rem;
  font-weight: 700;
  color: #EB672C;
  margin-bottom: 0.5rem; }

.ce_list li {
  font-size: 1.125rem;
  padding: 0.5rem 1rem 0.5rem 1.75rem;
  font-weight: 700;
  position: relative; }
  .ce_list li::before {
    content: url("../content/icons/physiotherapie-witzel-hook-icon.svg");
    position: absolute;
    left: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 100%; }

.ce_text ul {
  margin-bottom: 1.5rem; }

.ce_text li {
  font-size: 1.125rem;
  margin: 0.25rem 0 0.25rem 0.75rem; }
  .ce_text li::before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    height: 0.25rem;
    width: 0.25rem;
    background: #5B5B5B;
    border-radius: 100%;
    transform: translateX(-0.5rem);
    margin-left: -0.25rem; }

.ce_accordion {
  border-bottom: 1px solid #dbdbdb;
  border-left: 3px solid #EB672C;
  margin-bottom: 0.25rem;
  cursor: pointer; }
  .ce_accordion .accordion {
    padding: 1rem; }
  .ce_accordion .toggler {
    padding: 1rem;
    padding-right: 2rem;
    position: relative; }
    .ce_accordion .toggler:focus {
      outline: none; }
    .ce_accordion .toggler::after {
      position: absolute;
      content: "+";
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 2.25rem;
      font-weight: 300;
      opacity: 0.33;
      transition: 250ms ease-in-out; }
    .ce_accordion .toggler.ui-state-hover::after, .ce_accordion .toggler.ui-state-active::after {
      opacity: 1;
      color: #EB672C; }
    .ce_accordion .toggler.ui-state-active::after {
      transform: translateY(-50%) rotate(45deg); }

a, span {
  color: #EB672C;
  text-decoration: none;
  border-bottom: 1px solid #EB672C;
  font-size: 1.125rem;
  opacity: 1;
  transition: 250ms ease-in-out; }
  [id=navigation] .menu a, [id=footer] .menu a, [id=navigation] .menu span, [id=footer] .menu span {
    color: white;
    font-weight: 600;
    font-size: 1.125rem;
    border: none; }
  .has-mobile-nav [id=navigation] a, .has-mobile-nav [id=navigation] span {
    font-size: 1.5rem; }
  a:hover, span:hover {
    opacity: 0.66; }

[id=footer] .menu strong {
  color: #EB672C;
  font-weight: 600;
  font-size: 1.125rem;
  opacity: 0.66; }

.ce_hyperlink a {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 0; }

.button a {
  border: 2px solid #EB672C;
  padding: 0.5rem 1rem;
  border-radius: 2.5rem;
  transition: 250ms ease-in-out; }
  .button a:hover {
    background: #EB672C;
    color: white;
    opacity: 1; }

p {
  font-size: 1.125rem;
  line-height: 1.75; }

b, strong {
  font-weight: 700; }

em, i {
  font-style: italic; }

.copyline {
  padding-top: 1.25rem;
  text-align: center; }
  @media (min-width: 769px) {
    .copyline {
      padding-top: 0; } }
  .copyline span {
    font-size: 0.875rem;
    border-bottom: 0; }
    .copyline span:hover {
      opacity: 1; }

.scroll-down span {
  color: #EB672C;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.5); }

#main .tel {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 0;
  position: relative;
  padding-left: 2rem; }
  .ce_text #main .tel {
    margin-left: 0.25rem; }
  #main .tel::before {
    content: url("../../files/content/icons/witz_icons_tel.svg");
    position: absolute;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    vertical-align: middle;
    margin-right: 0.25rem;
    margin-left: -2.25rem;
    transform: translateY(-0.125rem); }
    .ce_text #main .tel::before {
      margin-left: -2.25rem; }

.mail .hyperlink_txt::before {
  content: url("../../files/content/icons/witz_icons_mail.svg");
  display: inline-block;
  height: 2rem;
  width: 2rem;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
  transform: translateY(-0.125rem); }

header .tel .hyperlink_img,
header .mail .hyperlink_img {
  display: inline-block;
  height: 3.25rem;
  width: 3.25rem; }

.icon-hamburger {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .icon-hamburger, .icon-hamburger::before, .icon-hamburger::after {
    width: 1rem;
    height: 0.125rem;
    background: white;
    transition: 333ms ease-in-out;
    border-radius: 0.125rem; }
  .icon-hamburger::before, .icon-hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    transform: rotate(0deg); }
  .icon-hamburger::before {
    top: -0.375rem; }
  .icon-hamburger::after {
    top: 0.375rem; }
  .show-mobile-nav .icon-hamburger {
    background-color: transparent; }
  .show-mobile-nav .icon-hamburger::before {
    top: 0;
    transform: rotate(45deg); }
  .show-mobile-nav .icon-hamburger::after {
    top: 0;
    transform: rotate(-45deg); }

.icon-close {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg); }
  .icon-close::before, .icon-close::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 0.125rem;
    background: white; }
  .icon-close::after {
    transform: translateX(-50%) rotate(90deg); }

@keyframes scrollDown {
  0% {
    opacity: 0;
    bottom: -1rem; }
  25% {
    opacity: 0.33; }
  50% {
    opacity: 1;
    bottom: -2rem; }
  75% {
    opacity: 0.33; }
  100% {
    opacity: 0;
    bottom: -3rem; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.scroll-down {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 9rem;
  cursor: pointer;
  background: none;
  border: none; }
  @media (min-width: 769px) {
    .scroll-down {
      opacity: 1;
      display: block;
      overflow: visible;
      animation-name: fadeIn;
      animation-duration: 500ms;
      animation-timing-function: ease-in-out;
      animation-delay: 2500ms;
      animation-fill-mode: forwards; } }
  .scroll-down:focus {
    outline: 0; }
  .scroll-down span {
    border-bottom: none; }
  .scroll-down img {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    opacity: 0;
    transition: 250ms ease-in-out;
    animation-name: scrollDown;
    animation-iteration-count: infinite;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    animation-delay: 2500ms;
    cursor: default; }

.mobile-menu-trigger {
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  display: block;
  z-index: 9999;
  height: 3rem;
  width: 3rem;
  background: #EB672C;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.5;
  transition: 250ms ease-in-out;
  border: 0.125rem solid rgba(255, 255, 255, 0); }
  @media (min-width: 769px) {
    .mobile-menu-trigger {
      display: none; } }
  .mobile-menu-trigger, .mobile-menu-trigger:focus, .mobile-menu-trigger:hover, .mobile-menu-trigger:active {
    outline: 0;
    box-shadow: none; }
  .mobile-menu-trigger span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .mobile-menu-trigger:hover {
    opacity: 1; }
  .show-mobile-nav .mobile-menu-trigger {
    opacity: 1;
    border: 0.125rem solid white;
    background: rgba(235, 103, 44, 0.95); }

.nav-back-trigger {
  position: fixed;
  top: 1.5rem;
  right: 2rem;
  display: block;
  z-index: 9999;
  height: 3rem;
  width: 3rem;
  background: #EB672C;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.5;
  transition: 250ms ease-in-out;
  border: 0.125rem solid rgba(255, 255, 255, 0); }
  .nav-back-trigger a {
    font: 0/0 a;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%; }
  .nav-back-trigger:hover {
    opacity: 1; }
  .nav-back-trigger, .nav-back-trigger:focus, .nav-back-trigger:hover, .nav-back-trigger:active {
    outline: 0;
    box-shadow: none; }

.dlh_googlemap {
  position: relative;
  padding-bottom: 66%;
  height: 0;
  width: 100%;
  overflow: hidden; }
  .dlh_googlemap > * {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 541px) {
    .dlh_googlemap {
      padding-bottom: 100%; } }
  @media (min-width: 769px) {
    .dlh_googlemap {
      padding-bottom: 75%; } }
  @media (min-width: 1025px) {
    .dlh_googlemap {
      padding-bottom: 60%; } }
  .dlh_googlemap .routinglink {
    font-size: 1rem;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem; }
    .dlh_googlemap .routinglink a {
      font-weight: 600;
      border-bottom: 0; }
    .dlh_googlemap .routinglink form {
      padding-top: 0.75rem; }
    .dlh_googlemap .routinglink input.submit {
      margin-left: 0.5rem; }

.background-img {
  z-index: -1;
  font: 0/0 a;
  overflow: hidden; }

#header .background-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media (min-width: 769px) {
    #header .background-img {
      height: calc(100vh - 4rem); } }
  #header .background-img figure {
    height: inherit;
    width: inherit; }
  #header .background-img img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto; }
    @supports (object-fit: cover) {
      #header .background-img img {
        position: static;
        height: 100%;
        width: 100%;
        transform: none;
        object-fit: cover; } }

#main .background-img {
  margin-top: -6.0625rem; }
  #main .background-img figure {
    position: relative;
    overflow: hidden; }
    #main .background-img figure::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 66.66667%; }
    #main .background-img figure > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto; }
    @media (min-width: 541px) {
      #main .background-img figure {
        position: relative;
        overflow: hidden; }
        #main .background-img figure::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 50%; }
        #main .background-img figure > * {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto; } }
    @media (min-width: 769px) {
      #main .background-img figure {
        position: relative;
        overflow: hidden; }
        #main .background-img figure::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 33.33333%; }
        #main .background-img figure > * {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto; } }
    @media (min-width: 1281px) {
      #main .background-img figure {
        max-height: 20rem; } }

@media (min-width: 541px) {
  #main .k-laser .ce_gallery ul {
    display: flex;
    width: 100%; }
  #main .k-laser .ce_gallery ul > li {
    padding: 1px;
    width: 33.33333%; } }

@media (min-width: 769px) {
  [id=navigation] {
    height: 0 !important; } }

[id=header] {
  position: relative;
  text-shadow: 2px 2px rgba(255, 255, 255, 0.5);
  min-height: 0 !important; }
  @media (min-width: 769px) {
    [id=header] {
      height: 100vh;
      padding-bottom: 4rem;
      display: flex;
      align-items: center; } }
  [id=header] .mod_article {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    background: rgba(255, 255, 255, 0.8);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.66), white, rgba(255, 255, 255, 0.33));
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center; }
    @media (min-width: 769px) {
      [id=header] .mod_article {
        border-radius: 1.5rem;
        background: rgba(255, 255, 255, 0.9);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.33)); } }
  [id=header] .logo img {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto; }
  [id=header] .logo figcaption {
    font-size: 1.25rem;
    margin-top: -0.25rem;
    margin-left: 2.25rem; }
  [id=header] .ce_headline {
    margin: 2.25rem 0;
    font-size: 2.5rem;
    text-align: center; }
    @media (min-width: 769px) {
      [id=header] .ce_headline {
        margin: 2.25rem 0;
        font-size: 3rem; } }
    @media (min-width: 1025px) {
      [id=header] .ce_headline {
        margin: 4.5rem 0;
        font-size: 3.5rem; } }
  [id=header] .icons {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center; }
    [id=header] .icons .ce_hyperlink:first-child {
      margin-right: 1rem; }
  [id=header] .ce_text.info {
    margin-bottom: 1rem; }
    [id=header] .ce_text.info p {
      font-weight: bold;
      font-size: 1.25rem; }
  [id=header] .ce_text.marginal p {
    text-shadow: 2px 2px rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    max-width: 28.75rem;
    margin-left: auto;
    margin-right: auto; }

[id=container] .inside > .block {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
  padding-bottom: 6rem;
  margin-top: 6rem;
  margin-bottom: 6rem; }
  [id=container] .inside > .block::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -9999px;
    right: -9990px;
    border-bottom: 1px solid #dbdbdb; }
  [id=container] .inside > .block.last {
    padding-bottom: 0; }
    [id=container] .inside > .block.last::after {
      content: none; }
  [id=container] .inside > .block > .block {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    [id=container] .inside > .block > .block.last {
      margin-bottom: 0; }
  [id=container] .inside > .block .ce_text > p {
    margin-bottom: 1.5rem; }
  [id=container] .inside > .block .ce_hgroup {
    margin-bottom: 3rem; }

[id=footer] .menu {
  background: white;
  border-top: 1px solid #EB672C;
  padding-top: 1.5rem;
  margin-top: 3rem; }
  [id=footer] .menu a, [id=footer] .menu span {
    color: #EB672C; }
    [id=footer] .menu a::after, [id=footer] .menu span::after {
      background: #EB672C; }
    [id=footer] .menu a:hover, [id=footer] .menu span:hover {
      opacity: 1; }

[id=footer] .copyline {
  margin-bottom: 3rem; }

.menu {
  background: #EB672C; }
  .menu.menu-main {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition: opacity 375ms ease-in-out 1ms, height 1ms 375ms;
    z-index: 8888;
    background: rgba(235, 103, 44, 0.95); }
    @media (min-width: 769px) {
      .menu.menu-main {
        opacity: 1;
        pointer-events: auto;
        position: relative;
        height: auto;
        transition: none;
        top: -4rem;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25); }
        .fixed-nav .menu.menu-main {
          position: fixed;
          top: 0; } }
    .show-mobile-nav .has-mobile-nav .menu.menu-main {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 375ms ease-in-out;
      height: 100vh; }
  .menu ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: inherit; }
    @media (min-width: 769px) {
      .menu ul {
        flex-direction: row; } }
  .menu li {
    padding: 1rem 1.125rem 1.25rem 1.125rem; }
  .menu a {
    position: relative;
    transition: opacity 250ms ease-in-out;
    opacity: 1; }
    .menu a::after {
      content: "";
      background: white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.625rem;
      height: 0.125rem;
      width: 60%;
      opacity: 0;
      transition: 250ms ease-in-out;
      pointer-events: none; }
    .menu a:hover::after {
      opacity: 0.75;
      bottom: -0.375rem;
      width: 100%; }
    .menu a:hover {
      opacity: 0.75; }
  .menu a.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .menu .jobs > a {
    background: white;
    color: #EB672C;
    border: none; }
    .menu .jobs > a::after {
      content: none; }
  .menu .back-to-top-link {
    padding: 1rem 0 1.25rem 0;
    transition: 250ms ease-in-out;
    opacity: 0;
    width: 0; }
    .menu .back-to-top-link a {
      background-image: url("../content/icons/physiotherapie-witzel-back-to-top-icon.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 1rem;
      width: 2rem;
      height: 100%;
      display: inline-block; }
      .menu .back-to-top-link a::after {
        content: none; }
    .menu .back-to-top-link.show {
      opacity: 1;
      width: 2rem; }
    .has-mobile-nav .menu .back-to-top-link {
      display: none; }

[class^="mod_news"] .info {
  font-size: 1rem;
  margin-bottom: 1rem; }

[class^="mod_news"] h3 > a {
  border-bottom: none; }

[class^="mod_news"] h3 {
  margin-bottom: 0.5rem; }

[class^="mod_news"] .ce_text > p {
  margin-bottom: 0.5rem !important; }

[class^="mod_news"] .more > a {
  font-size: 1rem;
  border-bottom: 0; }

.mod_newslist {
  width: 100%; }
  @media (min-width: 769px) {
    .mod_newslist {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .mod_newslist h2 {
        width: 100%;
        display: block; }
      .mod_newslist .layout_latest {
        width: 47.5%; } }
  .mod_newslist::after {
    content: none !important; }

.mod_newsreader::after, .mod_newsarchive::after {
  content: none !important; }

.mod_newsreader h2, .mod_newsarchive h2 {
  margin-bottom: 3rem; }

.private-services h1 {
  padding-bottom: 3rem; }

.private-services h3 {
  margin-bottom: 0.5rem; }

.private-services p {
  margin-bottom: 0.5rem !important; }

.private-services .ce_text.block {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 3rem; }
  .private-services .ce_text.block.last {
    border-bottom: 0;
    padding-bottom: 0; }
