#main .k-laser {
  // gallery
  .ce_gallery {

    @include screen(medium) {
      ul {
        display: flex;
        width: 100%;
      }

      ul > li {
        padding: 1px;
        width: percentage(1/3);
      }
    }
  }
}