#main .tel  {
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 0;
  position: relative;
  padding-left: 2rem;
  .ce_text & {
    margin-left: 0.25rem;
  }
  
  &::before {
    content: url('../../files/content/icons/witz_icons_tel.svg');
    position: absolute;
    display: inline-block;
    height: 2rem; width: 2rem;
    vertical-align: middle;
    margin-right: 0.25rem;
    margin-left: -2.25rem;
    .ce_text & {
      margin-left: -2.25rem;
    }    
    transform: translateY(-0.125rem);
  }
}

.mail .hyperlink_txt {
  &::before {
    content: url('../../files/content/icons/witz_icons_mail.svg');
    display: inline-block;
    height: 2rem; width: 2rem;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-left: -0.25rem;
    transform: translateY(-0.125rem);
  }
}

header .tel .hyperlink_img,
header .mail .hyperlink_img {
  display: inline-block;
  height: 3.25rem; width: 3.25rem;
}