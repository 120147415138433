////// design
  //// fonts
    $text-font-stack: 'Raleway', sans-serif;
    $code-font-stack: monospace !default;

  //// colors
    $text-color: #5B5B5B;
    $brand-color: #EB672C;
    $border-color: lighten($text-color, 50%);

////// layout
  //// breakpoints
    $break-small:       320px !default;
    $break-smedium:     540px !default;
    $break-medium:      768px !default;
    $break-large:      1024px !default ;
    $break-xlarge:     1280px !default;
    $break-xxlarge:    2560px !default;

  //// container, wrapper
    $max-width: $break-xlarge !default;

  //// media-queries
    $mq-small:            small;            // s
    $mq-smedium:          smedium;          // sm
    $mq-smedium-medium:   smedium-medium;   // sm-m
    $mq-medium:           medium;           // m
    $mq-medium-large:     medium-large;     // m-l
    $mq-large:            large;            // l
    $mq-large-xlarge:     large-xlarge;     // l-xl
    $mq-xlarge:           xlarge;           // xl

  //// semantic-grid
    // settings
    $gridClass: false; // true or false // default false
    $gridMargin: false; // true or false // default false
    $gutter-width: 7.5%;

    // vars
    $col-margin: 1.5rem;
    $columns: 12; // default 12

    // media-queries
    $media-queries-list: (
      // "small": s,
      // "smedium": sm,
      // "smedium-medium": sm-m,
      // "medium": m,
      // "medium-large": m-l,
      // "large": l,
      // "large-xlarge": l-xl,
      // "xlarge": xl,
    );