.icon-close {
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);

  &::before, &::after {
    content: "";
    position: absolute;
    left: 50%; transform: translateX(-50%);
    width: 1rem; height: 0.125rem;
    background: white;
  }
  
  &::after {
    transform: translateX(-50%) rotate(90deg);
  }
}