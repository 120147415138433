.nav-back-trigger {
  position: fixed;
  top: 1.5rem; right: 2rem;
  display: block;
  z-index: 9999;
  height: 3rem; width: 3rem;
  background: $brand-color;
  border-radius: 100%;
  cursor: pointer;
  opacity: 0.5;
  transition: 250ms ease-in-out;
  border: 0.125rem solid rgba(white, 0);
  
  a {
    font: 0/0 a;
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  
  &:hover {
    opacity: 1;
  }
  
  &, &:focus, &:hover, &:active {
    outline: 0; // reset
    box-shadow: none; // reset
  }
}