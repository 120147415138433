[id=header] {
  position: relative;
  text-shadow: 2px 2px rgba(white, 0.5);
  min-height: 0 !important;
  
  @include screen(large) {
    height: 100vh;
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
  }
  
  .mod_article {
    @include wrapper;
    background: rgba(white, 0.8);
    background: linear-gradient(135deg, rgba(white,0.66), rgba(white,1), rgba(white,0.33));
    padding: {
      top: 3rem;
      bottom: 3rem;
    }
    text-align: center;
    @include screen(large) {
      border-radius: 1.5rem;
      background: rgba(white, 0.9);
      background: linear-gradient(135deg, rgba(white,0.5), rgba(white,0.9), rgba(white,0.33));
    }
  }
  
  
  // logo
  .logo {
    
    img {
      max-width: 20rem;
      margin: { left: auto; right: auto; }
    }
    
    figcaption {
      font-size: 1.25rem;
      margin-top: -0.25rem;
      margin-left: 2.25rem;
     }
  }

  // headline
  .ce_headline {
    margin: 2.25rem 0;
    font-size: 2.5rem;
    text-align: center;
    
    @include screen(large) {
      margin: 2.25rem 0;
      font-size: 3rem;
    }
    
    @include screen(xlarge) {
      margin: 4.5rem 0;
      font-size: 3.5rem;
    }
  }
  
  
  // icons
  .icons {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
  
    .ce_hyperlink:first-child {
      margin-right: 1rem;
    }
  }
  
  // infos
  .ce_text.info {
    margin-bottom: 1rem;
    
    p {
      font-weight: bold;
      font-size: 1.25rem;
    }
  }
  
  // marginal
  .ce_text.marginal {
    
    p {
      text-shadow: 2px 2px rgba(white, 0.5);
      font-size: 1rem;
      max-width: 28.75rem;
      margin: { left: auto; right: auto; }
    }
  }
}