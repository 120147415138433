.private-services {
  
  h1 {
    padding-bottom: 3rem;
  }
  
  h3 {
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-bottom: 0.5rem !important;
  }
  
  .ce_text {
    
    &.block {
      border-bottom: 1px solid $border-color;
      padding-bottom: 3rem;
      
      &.last {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}