// background-img -> header
.background-img {
  z-index: -1;
  font: 0/0 a;
  overflow: hidden;
}

#header .background-img {
  position: absolute;
  left: 0; top: 0;
  width: 100%; 
  height: 100%;
  @include screen(large) {
    height: calc(100vh - 4rem);
  }
  
  figure {
    height: inherit; width: inherit;
  }
  
  img {
    position: absolute;
    top: 0; left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
    
    @supports (object-fit: cover) {
      position: static;
      height: 100%; width: 100%;
      transform: none;
      object-fit: cover;
    }
  }
}

#main .background-img {
  margin-top: -6.0625rem;

  figure {
    @include aspect-ratio(3,2);
    @include screen(medium) { @include aspect-ratio(3,1.5); }
    @include screen(large) { @include aspect-ratio(3,1); }
    @include screen(xxlarge) {
      max-height: 20rem;
    }
  }
}