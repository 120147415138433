[id=footer] {

  & .menu {
    background: white;
    border-top: 1px solid $brand-color;
    padding-top: 1.5rem;
    margin-top: 3rem;

    a, span {
      color: $brand-color;

      &::after {
        background: $brand-color;
      }

      &:hover {
        opacity: 1;
      }
    }
  
  }
  
  & .copyline {
    margin-bottom: 3rem;
  }

}

