///// media queries

@mixin screen($size) {
  
  // @include screen(small)
  @if $size == $mq-small {
    @media (max-width: $break-small) { @content; }
  }

  // @include screen(smedium)
  @else if $size == $mq-smedium {
    @media (min-width: $break-small + 1px) { @content; }
  }
  
  // @include screen(smedium-large)
  @else if $size == $mq-smedium-medium {
    @media (min-width: $break-small + 1px) and (max-width: $break-smedium)  { @content; }
  }    
  
  // @include screen(medium)
  @else if $size == $mq-medium {
    @media (min-width: $break-smedium + 1px) { @content; }
  }

  // @include screen(large-xlarge)
  @else if $size == $mq-medium-large {
    @media (min-width: $break-smedium + 1px) and (max-width: $break-medium)  { @content; }
  }   
  
  // @include screen(large)
  @else if $size == $mq-large {
    @media (min-width: $break-medium + 1px)  { @content; }
  }
  
  // @include screen(large-xlarge)
  @else if $size == $mq-large-xlarge {
    @media (min-width: $break-medium + 1px) and (max-width: $break-large)  { @content; }
  }  
  
  // @include screen(xlarge)
  @else if $size == $mq-xlarge {
    @media (min-width: $break-large + 1px)  { @content; }
  }
  
  // @include screen(xxlarge)
  @else if $size == xxlarge {
    @media (min-width: $break-xlarge + 1px)  { @content; }
  }

  // @include screen(xxxlarge)
  @else if $size == xxxlarge {
    @media (min-width: $break-xxlarge + 1px)  { @content; }
  }
}
