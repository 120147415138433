// googleMaps
.dlh_googlemap  {
  position: relative;
  padding-bottom: 66%;
  height: 0; width: 100%;
  overflow: hidden;
  // border: 1px solid red;
  
  & > * {
    pointer-events: none; // disable scroll
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @include screen(medium) {
    padding-bottom: 100%;
  }
  
  @include screen(large) {
    padding-bottom: 75%;
  }
  
  @include screen(xlarge) {
    padding-bottom: 60%;
  }
  
  // routinglink
  .routinglink {
    font-size: 1rem;
    padding: 0.5rem 0.25rem 0.25rem 0.25rem;
    
    a {
      font-weight: 600;
      border-bottom: 0;
    }
    
    form {
      padding-top: 0.75rem;
    }
    
    input.submit {
      margin-left: 0.5rem;
    }
  }
}