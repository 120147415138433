[id=container] {
  
  // modules
  .inside>.block {
    @include wrapper;
    position: relative;
    padding-bottom: 6rem;
    margin: {
      top: 6rem;
      bottom: 6rem;
    }
    
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -9999px; right: -9990px;
      border-bottom: 1px solid $border-color;
    }
    
    &.last {
      padding-bottom: 0;
      
      &::after {
        content: none;
      }
    }
    
    // content-elements
    >.block {
      margin: {
        top: 3rem;
        bottom: 3rem;
      }
      
      // last-element
      &.last {
        margin-bottom: 0;
      }
    }
    
    // ce_text
    .ce_text > p {
      margin-bottom: 1.5rem;
    }
    
    // ce_hgroup
    .ce_hgroup {
      margin-bottom: 3rem;
    }
  }
}