///// base

/// box-sizing
html {
  box-sizing: border-box;
  font-size: 90%;
  @include screen(medium) {
    font-size: 100%;
  }
}

* , *::before, *::after {
  box-sizing: inherit;
}


/// font-smoothing
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font: normal 16px / 1.5 $text-font-stack;
}


/// invisible
.invisible {
  @include visually-hidden;
}

// img
img {
  width: 100%; height: auto;
}

// wrapper
#wrapper {
  overflow-x: hidden;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  
  #container {
    flex: 1 0 auto;
  }
}

