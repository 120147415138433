///// helpers: mixins, functions

/// clearfix
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}


/// container
@mixin container {
  max-width: $max-width;
  margin: { left: auto; right: auto; }
  width: 100%;
}


/// hide-text
@mixin hide-text {
  overflow: hidden;
  padding: 0;
  text-indent: 101%;
  white-space: nowrap;
}


// visually-hidden
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


// aspect-ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  overflow: hidden;
  
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  
  > * {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; height: auto;
  }
}

// wrapper
@mixin wrapper {
  max-width: $max-width;
  margin: { 
    left: auto; 
    right: auto;
  }
  padding: { 
    left: 5%; 
    right: 5%; 
  }
}