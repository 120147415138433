.menu {
  background: $brand-color;
  
  &.menu-main {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0; left: 0;
    height: 0;
    width: 100%;
    transition: opacity 375ms ease-in-out 1ms, height 1ms 375ms;
    z-index: 8888;
    background: rgba($brand-color, 0.95);
    
    @include screen(large) {
      opacity: 1;
      pointer-events: auto;
      position: relative;
      height: auto;
      transition: none;
      top: -4rem;
      box-shadow: 0 0 1rem 0 rgba(black, 0.25);
      
      .fixed-nav & {
        position: fixed;
        top: 0;
      }
    }
    
    .show-mobile-nav .has-mobile-nav & {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 375ms ease-in-out;
      height: 100vh;
    }
  }
  
  ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: inherit;
    
    @include screen(large) {
      flex-direction: row;
    }
  }
  
  li {
    padding: 1rem 1.125rem 1.25rem 1.125rem; 
  }
  
  a {
    position: relative;
    transition: opacity 250ms ease-in-out;
    opacity: 1;
    
    &::after {
      content: "";
      background: white;
      position: absolute;
      left: 50%; 
      transform: translateX(-50%);
      bottom: -0.625rem;
      height: 0.125rem;
      width: 60%;
      opacity: 0;
      transition: 250ms ease-in-out;
      pointer-events: none;
    }
    
    &:hover::after {
      opacity: 0.75;
      bottom: -0.375rem;
      width: 100%;
    }
    
    &:hover {
      opacity: 0.75;
    }
  }
  
  // hidden invisible
  & a.invisible {
    @include visually-hidden;
  }
    
  // jobs navigation-item
  & .jobs {
  
    & > a {
      background: white;
      color: $brand-color;
      border: none;

      &::after {
        content: none;
      }
    }
  }
  
    // back-to-top-link 
  & .back-to-top-link {
    padding: 1rem 0 1.25rem 0;
    transition: 250ms ease-in-out;
    opacity: 0;
    width: 0;
    
    & a {
      background: {
        image: url('../content/icons/physiotherapie-witzel-back-to-top-icon.svg');
        position: center center;
        repeat: no-repeat;
        size: 1rem;
      }          
      width: 2rem; height: 100%;
      display: inline-block;
      
      &::after {
        content: none;
      }
    }
    
    &.show {
      opacity: 1;
      width: 2rem;
    }
    
    .has-mobile-nav & {
      display: none;
    }
  }
}