.icon-hamburger {
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);

  &, &::before, &::after {
    width: 1rem; height: 0.125rem;
    background: white;
    transition: 333ms ease-in-out;
    border-radius: 0.125rem;
  }

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    transform: rotate(0deg);
  }

  &::before {
    top: -0.375rem;
    
  }

  &::after {
    top: 0.375rem;
  }
  
  // animation
  .show-mobile-nav & {
    & {
      background-color: transparent;
    }

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      top: 0;
      transform: rotate(-45deg);
    }
  }
}